<template>
  <div class="grid max-lg:grid-cols-2 max-lg:gap-y-4" :class="`grid-cols-${items.length}`">
    <template v-for="(item, index) in items" :key="index">
      <div class="sm:w-72 w-full" :class="{
        'pr-4': index < items.length - 1,
      }">
        <div class="font-semibold mb-3 max-lg:pt-8">{{ getText(item.label, item.translated) }}</div>

        <ul class="flex flex-col gap-y-3">
          <template v-for="(navItem, idx) in item.items" :key="idx">
            <li><a class="text-gray-500" :href="navItem.link">{{ getText(navItem.label, navItem.translated) }}</a></li>
          </template>
        </ul>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import i18n from '@/translations';

const props = withDefaults(defineProps<{
  items: Array<FooterNavigationItem>,
}>(), {});

const { t } = i18n.global;
function getText(text: string | undefined, translated: boolean | undefined) {
  if (text && translated) {
    return t(text);
  }
  return text ?? '';
}

</script>
