import {createApp, type Directive} from 'vue';
import {createPinia} from 'pinia';
import i18n from '@/translations';
import LmsPartners from 'lms-components/src/components/data-display/LmsPartners.vue';
import {clickOutside} from 'lms-components/src/Utilities/directives';
import type {PartnerContainer} from 'lms-components/src/types/componentTypes';
import type {Link} from 'lms-components/src/types/generalTypes';

// This is the point where the naming changes.
// The Extensions used 'sponsors' but in the new version it is called 'partners'.
// To not change every thing, we start with sponsors in th TYPO3 environment and change it to partners in the LmsComponents.
export function initSponsors(sponsors: HTMLElement) {
    const linkParameters = JSON.parse(sponsors.dataset.tx_messesponsor_link ?? '[]');
    const partnersParameters = JSON.parse(sponsors.dataset.tx_messesponsor_list ?? '[]');

    const link: Link = linkParameters.length > 0 ? {
        url: linkParameters[0]?.attributes?.href ?? '',
        title: linkParameters[0].attributes.title ?? linkParameters[0]?.children[0] ?? '',
        target: linkParameters[0]?.attributes?.target ?? '_self',
    } : {
        url: '',
        title: '',
        target: '_self',
    };

    const partners: PartnerContainer = {
        headline: sponsors.dataset.tx_messesponsor_headline ?? '',
        partnerSliders: partnersParameters.map((partner: any) => ({
            headline: partner.data?.tx_messesponsor_list_title ?? '',
            partners: partner.data?.tx_messesponsor_list_sponsors?.length > 0
                ? partner.data?.tx_messesponsor_list_sponsors.map((partner: any) => ({
                    name: partner.data?.title ?? '',
                    logo: {
                        src: partner.logo?.[0]?.attributes?.src ?? '',
                        alt: partner.logo?.[0]?.attributes?.alt ?? '',
                    },
                    link: {
                        url: partner.link?.[0]?.attributes?.href ?? '',
                        title: partner.link?.[0]?.children?.[0] ?? '',
                        target: partner.link?.[0]?.attributes?.target ?? '',
                    },
                })) : [],
        })),
        link: link,
    };

    createApp(LmsPartners, {
        partners,
    })
        .use(i18n)
        .use(createPinia())
        .directive('clickOutside', clickOutside as Directive)
        .mount(sponsors);
}