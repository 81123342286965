import LmsLinkList from 'lms-components/src/components/data-display/LmsLinkList.vue';
import { createApp } from 'vue';

export function initLinkList(linkList: HTMLElement) {
    const headline = linkList.dataset.headline ?? '';
    let imgMobile = JSON.parse(linkList.dataset.imgMobile ?? '{}');
    imgMobile = imgMobile?.length ? imgMobile[0] : imgMobile;
    const linkItems = JSON.parse(linkList.dataset.linkItems ?? '[]');

    createApp(LmsLinkList, {
        headline,
        imgMobile,
        linkItems,
    }).mount(linkList);
}
