import global from './global';
import ui from 'lms-components/src/translations/en/ui';
import forms from 'lms-components/src/translations/en/forms';
import header from 'lms-components/src/translations/en/header';import fairCalendar from 'lms-components/src/translations/en/fairCalendar';
import footer from './footer';
export default {
  global,
  ui,
  forms,
  header,
  fairCalendar,
  footer,
};
