import { createApp, type Directive } from 'vue';
import { createPinia } from 'pinia';
import i18n from '@/translations';
import LmsHeader from 'lms-components/src/components/layout/header/LmsHeader.vue';
import { clickOutside } from 'lms-components/src/Utilities/directives';

export function initHeader(header: HTMLElement) {
  const navigationItems = JSON.parse(header.dataset.mainNavigation ?? '{}');
  const contactItems = JSON.parse(header.dataset.contactItems ?? '[]');
  const accessibilityItems = JSON.parse(header.dataset.accessibilityItems ?? '[]');
  const serviceItems = JSON.parse(header.dataset.serviceNavigation ?? '{}');
  const languages = JSON.parse(header.dataset.languages ?? '[]');
  const fairEventCalendarUrl = header.dataset.fairEventCalendarUrl ?? '';
  const ticketShopUrl = header.dataset.ticketShopUrl ?? '';
  const internalFairUid = Number.parseInt(header.dataset.internalFairUid ?? '0', 10);
  const mainNavigationColor = JSON.parse(header.dataset.mainNavigationColor ?? '');
  const loginPage = header.dataset.loginPage ?? '';
  const searchPageUrl = header.dataset.searchPageUrl ?? '';
  const fairPath = header.dataset.fairPath ?? '';
  const fairLogo = JSON.parse(header.dataset.fairLogo ?? '{}');
  const navigationCustomHeader = header.dataset.customHeaderNavigation ? JSON.parse(header.dataset.customHeaderNavigation ?? '[]') : null;
  const locationItems = header.dataset.locationItems ? JSON.parse(header.dataset.locationItems ?? '[]') : null;
  const eventOverwrite = header.dataset.eventOverwrite ? JSON.parse(header.dataset.eventOverwrite ?? '{}') : null;
  let fairDate;
  try {
    fairDate = JSON.parse(header.dataset.fairDate ?? '');
  } catch(e) {
    fairDate = header.dataset.fairDate ?? '';
  }
  const fairSlogan = header.dataset.fairSlogan ?? '';
  const loginUrl = header.dataset.loginUrl ?? '';
  const hideTicketShopBtnRootPage = header.dataset.hideTicketShopBtnRootPage ? JSON.parse(header.dataset.hideTicketShopBtnRootPage) : '';
  const customContactAddress = header.dataset.customContactAddress ? JSON.parse(header.dataset.customContactAddress ?? '[]') : null;

  createApp(LmsHeader, {
    navigationItems,
    loginPage,
    searchPageUrl,
    contactItems,
    accessibilityItems,
    serviceItems,
    languages,
    fairEventCalendarUrl,
    mainNavigationColor,
    fairPath,
    ticketShopUrl,
    internalFairUid,
    fairLogo,
    fairDate,
    fairSlogan,
    loginUrl,
    navigationCustomHeader,
    locationItems,
    eventOverwrite,
    hideTicketShopBtnRootPage,
    customContactAddress,
  })
    .use(i18n)
    .use(createPinia())
    .directive('clickOutside', clickOutside as Directive)
    .mount(header);
}
