export const LANGUAGE_DE = 'de';
export const LANGUAGE_EN = 'en';
export const DEFAULT_LANGUAGE = LANGUAGE_DE;

export const PRIMARY_NAVIGATION_ACTION_BUTTON_COLOR = 0;
export const SECONDARY_NAVIGATION_ACTION_BUTTON_COLOR = 1;
export const MAX_SECOND_LVL_NAV_ITEMS = 7;
export const MAX_THIRD_LVL_NAV_ITEMS = 12;

export const FAIR_UFI_ICON = 'fair_ufi_icon';

export const FAIR_SUSTAINABILITY_AWARD_ICON = 'fair_german_sustainability_award_icon';

export const FAIR_FKM_ICON = 'fair_fkm_icon';

export const FAIR_AUMA_ICON = 'fair_auma_icon';

export const LOGO_DE = 'https://www.messe-stuttgart.de/fileadmin/_gcr_/media/allgemein/lms.svg';
export const LOGO_EN = 'https://www.messe-stuttgart.de/fileadmin/_gcr_/media/allgemein/lms-en.svg';

export const CORPORATE_DE = 'https://www.messe-stuttgart.de/';
export const CORPORATE_EN = 'https://www.messe-stuttgart.de/en/';
