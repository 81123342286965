import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from '@/translations';
import LmsFairCalendar from 'lms-components/src/components/data-display/fairCalendar/LmsFairCalendar.vue';

export function initFairCalendar(fairCalendar: HTMLElement) {
  const listItems = fairCalendar.dataset.listItems ? JSON.parse(fairCalendar.dataset.listItems ?? '[]') : [];
  const locations = fairCalendar.dataset.locations ? JSON.parse(fairCalendar.dataset.locations ?? '[]') : [];
  const csvDownloadLink = fairCalendar.dataset.csvDownloadLink;
  const isUsercentricsAvailable = fairCalendar.dataset.usercentrics;
  const googleMapsApiKey = fairCalendar.dataset.googleMapsApiKey;

  const header: HTMLElement | null = document.querySelector('.new-header');
  let fairPath = '';

  if (header) {
    fairPath = header.dataset.fairPath ? `/${header.dataset.fairPath}` : '';
  }

  const headerImage = `${fairPath}/typo3conf/ext/plus_project/vite/src/assets/fairCalendar/header.jpg`;

  createApp(LmsFairCalendar, {
    listItems,
    locations,
    csvDownloadLink,
    headerImage,
    isUsercentricsAvailable,
    googleMapsApiKey,
  })
    .use(i18n)
    .use(createPinia())
    .mount(fairCalendar);
}
