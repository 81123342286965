export function initGlobalStorageEvents() {
  const originalSetItem = localStorage.setItem;
  localStorage.setItem = function(key: string, value: string) {
    const event = new Event('localStorageSetItem') as LocalStorageEvent;
    event.key = key;
    event.value = value;
    originalSetItem.apply(this, [key, value]);
    document.dispatchEvent(event);
  };

  const originalGetItem = localStorage.getItem;
  localStorage.getItem = function(key: string) {
    const event = new Event('localStorageGetItem') as LocalStorageEvent;
    event.key = key;
    document.dispatchEvent(event);
    return originalGetItem.apply(this, [key]);
  };

  const originalRemoveItem = localStorage.removeItem;
  localStorage.removeItem = function(key: string) {
    const event = new Event('localStorageRemoveItem') as LocalStorageEvent;
    event.key = key;
    originalRemoveItem.apply(this, [key]);
    document.dispatchEvent(event);
  };
}
