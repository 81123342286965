<template>
  <footer
      class="container mx-auto text-sm relative"
      ref="footer"
  >
    <div
        class="flex justify-between flex-col lg:flex-row"
    >
      <lms-footer-nav
          :items="footerNavItems"
      />

      <div class="pt-12 max-lg:border-t">
        <div class="flex flex-col gap-y-1">
          <div class="font-semibold">{{contactDetails.companyName}}</div>
          <div class="text-gray-600">{{contactDetails.addressLine1}}</div>
          <div class="text-gray-600">{{ contactDetails.addressLine2 }}</div>
        </div>

        <template v-if="footerIcons?.length">
          <div class="flex flex-row mt-3 gap-3">
          <template v-for="(item, index) in footerIcons" :key="index">
            <a :href="item.link" :target="item.target">
              <component :is="item.icon" class="w-7 h-7" />
            </a>
          </template>
        </div>
        </template>

        <div
            class="flex flex-row flex-wrap gap-8 mt-8 xxs:w-full xs:w-[40%] sm:w-[19%] lg:w-[70%] lg:mt-20"
        >
          <template v-for="(memberChunk, index) in members" :key="index">
            <div class="flex flex-row gap-8">
              <template v-for="(member, idx) in memberChunk" :key="idx">
                <a :href="member.link" target="_blank">
                  <!--                if the member height is changing please change it also in tailwind.config.js -->
                  <img
                      :src="member.image"
                      :width="member.width"
                      :height="member.height"
                      :title="member.title"
                      :alt="member.alt"
                      class="object-contain"
                      :class="`max-h-[${member.height}px] h-[${member.height}px]`"
                  />
                </a>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>

    <template v-if="appIcons?.length">
      <div class="lg:mb-[-1.5rem] max-lg:mt-8">
        <div class="font-semibold mb-3">
          {{ $t('footer.icons.fairApp') }}
        </div>

        <div class="flex gap-x-3">
          <template v-for="icon in appIcons" :key="icon.id">
            <a :href="icon.link">
              <template v-if="icon.id === 'fair_play_app'">
                <google-play-icon
                    class="h-[2.0625rem]"
                    :language="LanguageService.getCurrentTYPO3Language()"
                />
              </template>

              <template v-if="icon.id === 'fair_apple_app'">
                <apple-store-icon
                    class="h-[2.0625rem]"
                    :language="LanguageService.getCurrentTYPO3Language()"
                />
              </template>
            </a>
          </template>
        </div>
      </div>
    </template>

    <div
        class="flex gap-x-10 gap-y-4 flex-row mt-12 border-t pt-4 pb-4 max-lg:flex-col max-lg:mt-12 max-lg:pt-8"
    >
      <a
          href="#"
          onclick="UC_UI.showSecondLayer();"
          :title="$t('header.metaNav.privacySettings')"
          data-auto-event-observed="true"
      >
        {{ $t('header.metaNav.privacySettings') }}
      </a>

      <template v-for="(item, index) in footerMetaNavItems" :key="index">
        <a :href="item.link" :target="item.target">
          {{ $t(item.label) }}
        </a>
      </template>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { computed, type Ref, ref } from 'vue';
import i18n from '@/translations';
import LmsFooterNav from '@/vue/components/layout/FooterComponents/LmsFooterNav.vue';
import IIonLogoXing from '~icons/ion/logo-xing';
import IMdiWhatsapp from '~icons/mdi/whatsapp';
import IMdiSnapchat from '~icons/mdi/snapchat';
import Facebook from "@/vue/svg/Facebook.vue";
import Instagram from "@/vue/svg/Instagram.vue";
import LinkedIn from "@/vue/svg/LinkedIn.vue";
import Youtube from "@/vue/svg/Youtube.vue";
import X from "@/vue/svg/X.vue";
import IndustryArena from "@/vue/svg/IndustryArena.vue"

import {
  FAIR_AUMA_ICON,
  FAIR_FKM_ICON,
  FAIR_SUSTAINABILITY_AWARD_ICON,
  FAIR_UFI_ICON,
  MAX_SECOND_LVL_NAV_ITEMS,
} from '@/constants';
import LanguageService from 'lms-components/src/services/LanguageService';
import {arrayChunk} from 'lms-components/src/Utilities/misc';
import GooglePlayIcon from "@/vue/svg/GooglePlayIcon.vue";
import AppleStoreIcon from "@/vue/svg/AppleStoreIcon.vue";

const { t } = i18n.global;

const footer: Ref<HTMLElement | null> = ref(null);

const props = withDefaults(
    defineProps<{
      navigationItems: Typo3NavigationItem[];
      metaNavigationFooter: Typo3NavigationItem[];
      memberLogos: Logo[];
      appIcons: Logo[];
      socialMedia: FooterIcons[];
      navigationFooter: Typo3NavigationItem[];
      fairPath: string;
      navigationCustomHeader?: Typo3CustomHeaderNavigation | null;
      customContactAddress?: ContactDetails | null;
    }>(),
    {},
);

const getFairPath = computed(() =>
    props.fairPath ? `/${props.fairPath}` : '',
);

const members = computed<Logo[][]>(() => {
  const memberLogos: Logo[] = [];
  props.memberLogos.forEach((item) => {
    if (item.active === 1) {
      if (item.title === FAIR_UFI_ICON) {
        memberLogos.push({
          image: `${getFairPath.value}/typo3conf/ext/plus_project/vite/src/assets/logo/logo-ufi.jpg`,
          width: '70',
          height: '50',
          link: item.link,
          alt: 'ufi Logo',
          title: 'ufi Logo',
          id: FAIR_UFI_ICON,
          active: item.active,
        });
      } else if (item.title === FAIR_SUSTAINABILITY_AWARD_ICON) {
        memberLogos.push({
          image: `${getFairPath.value}/typo3conf/ext/plus_project/vite/src/assets/logo/award_icon.svg`,
          width: '70',
          height: '50',
          link: item.link,
          alt: 'Award Icon',
          title: 'Award Icon',
          id: FAIR_SUSTAINABILITY_AWARD_ICON,
          active: item.active,
        });
      } else if (item.title === FAIR_FKM_ICON) {
        memberLogos.push({
          image: `${getFairPath.value}/typo3conf/ext/plus_project/vite/src/assets/logo/logo-fkm.jpg`,
          width: '70',
          height: '50',
          link: item.link,
          alt: 'FKM Logo',
          title: 'FKM Logo',
          id: FAIR_FKM_ICON,
          active: item.active,
        });
      } else if (item.title === FAIR_AUMA_ICON) {
        memberLogos.push({
          image: `${getFairPath.value}/typo3conf/ext/plus_project/vite/src/assets/logo/auma_logo.svg`,
          width: '70',
          height: '50',
          link: item.link,
          alt: 'AUMA Logo',
          title: 'AUMA Logo',
          id: FAIR_AUMA_ICON,
          active: item.active,
        });
      }
    }
  });

  const iconOrder = [
    FAIR_AUMA_ICON,
    FAIR_UFI_ICON,
    FAIR_FKM_ICON,
    FAIR_SUSTAINABILITY_AWARD_ICON,
  ];

  return arrayChunk<Logo>(memberLogos.sort(
      (a, b) => iconOrder.indexOf(a.id!) - iconOrder.indexOf(b.id!),
  ), 2);
});

const footerNavItems = computed<FooterNavigationItem[]>(() => {
  if (!props.navigationFooter || props.navigationCustomHeader) {
    return [];
  }

  return props.navigationFooter.map((nav: Typo3NavigationItem) => ({
    label: nav.title,
    items: nav.children
      ? nav.children.slice(0, MAX_SECOND_LVL_NAV_ITEMS).map((child) => ({
          label: child.title,
          link: child.link,
          target: child.target,
        }))
      : [],
  })) as FooterNavigationItem[];
});

const footerIcons = computed<FooterIcons[]>(() => {
  const socialMediaIcons: FooterIcons[] = [];
  props.socialMedia.map((media) => {
    const socialMediaName = Object.keys(media)[0];
    const titleAndLink = Object.values(media)[0];

    switch (socialMediaName) {
      case 'facebook':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: Facebook,
          link: titleAndLink,
          target: '_blank',
        });
        break;
      case 'x':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: X,
          link: titleAndLink,
          target: '_blank',
        });
        break;
      case 'linkedin':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: LinkedIn,
          link: titleAndLink,
          target: '_blank',
        });
        break;
      case 'youtube':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: Youtube,
          link: titleAndLink,
          target: '_blank',
        });
        break;
      case 'xing':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: IIonLogoXing,
          link: titleAndLink,
          target: '_blank',
        });
        break;
      case 'instagram':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: Instagram,
          link: titleAndLink,
          target: '_blank',
        });
        break;
      case 'whatsapp':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: IMdiWhatsapp,
          link: titleAndLink,
          target: '_blank',
        });
        break;
      case 'snapchat':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: IMdiSnapchat,
          link: titleAndLink,
          target: '_blank',
        });
        break;
      case 'industry_arena':
        socialMediaIcons.push({
          title: titleAndLink,
          icon: IndustryArena,
          link: titleAndLink,
          target: '_blank',
        });
        break;
    }
  });
  return socialMediaIcons as FooterIcons[];
});

const footerMetaNavItems = computed<NavigationItem[]>(() => {
  if (!props.metaNavigationFooter) {
    return [];
  }

  return props.metaNavigationFooter.map((nav: Typo3NavigationItem) => ({
    label: nav.title,
    link: nav.link,
    target: nav.target,
  })) as NavigationItem[];
});

const contactDetails = computed<ContactDetails>(() => {
  const email = props.customContactAddress?.email || 'info@messe-stuttgart.de';
  const formattedEmail = email.replace('@', '(at)');

  return {
    companyName: props.customContactAddress?.companyName || 'Landesmesse Stuttgart GmbH & Co. KG',
    addressLine1: props.customContactAddress?.addressLine1 || 'Messepiazza 1',
    addressLine2: props.customContactAddress?.addressLine2 || '70629 Stuttgart',
    phone: props.customContactAddress?.phone || '+49 711 18560 0',
    email: email,
    formattedEmail: formattedEmail
  };
});

defineExpose({ footer });
</script>
