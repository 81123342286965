import en from './en/';
import de from './de/';
import LanguageService from 'lms-components/src/services/LanguageService';
// @ts-ignore
import { createI18n } from 'vue-i18n';

const translations = {
  en,
  de,
};

export default createI18n({
  legacy: false,
  locale: LanguageService.getCurrentTYPO3Language(),
  messages: translations,
});
