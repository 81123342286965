import {createApp, type Directive} from 'vue';
import {createPinia} from 'pinia';
import i18n from '@/translations';
import LmsQuickFacts from 'lms-components/src/components/data-display/LmsQuickFacts.vue';
import {clickOutside} from 'lms-components/src/Utilities/directives';
import type { Link, Image, Media } from 'lms-components/src/types/generalTypes';
import { MEDIA_TYPES } from 'lms-components/src/types/generalTypes';
import type { QuickFact } from "lms-components/src/types/componentTypes";

export function initQuickFacts(header: HTMLElement) {
    const headline = header.dataset.header ?? '';
    const hideHeadline = header.dataset.headerHidden === 'true';
    const bodyText = header.dataset.bodyText ?? '';
    const linkParameters = JSON.parse(header.dataset.link ?? '');

    let link: Link = {
        url: '',
        target: '_self',
        title: '',
    };
    if (linkParameters.length) {
        link = {
            url: linkParameters[0].attributes.href ?? '',
            target: linkParameters[0].attributes.target ?? '_self',
            title: linkParameters[0].attributes.title ?? linkParameters[0]?.children[0] ?? '',
        };
    }

    const facts: QuickFact[] = [
        {
            headline: header.dataset.fact_1_number ?? '',
            bodyText: header.dataset.fact_1_text ?? ''
        },
        {
            headline: header.dataset.fact_2_number ?? '',
            bodyText: header.dataset.fact_2_text ?? ''
        },
        {
            headline: header.dataset.fact_3_number ?? '',
            bodyText: header.dataset.fact_3_text ?? ''
        },
        {
            headline: header.dataset.fact_4_number ?? '',
            bodyText: header.dataset.fact_4_text ?? ''
        }
    ];

    const media: Media = {
        src: header.dataset.assets ?? '',
        alt: header.dataset.assetsAlt ?? '',
        type: parseInt(header.dataset.mediaType ?? '', 10) ?? MEDIA_TYPES.IMAGE,
    };
    const mobileImage: Image = {
        src: header.dataset.image ?? '',
        alt: header.dataset.imageAlt ?? '',
    };

createApp(LmsQuickFacts, {
    headline,
    hideHeadline,
    bodyText,
    link,
    facts,
    media,
    mobileImage
})
    .use(i18n)
    .use(createPinia())
    .directive('clickOutside', clickOutside as Directive)
    .mount(header);
}
