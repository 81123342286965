import { createApp } from 'vue';
import i18n from '@/translations';
import LmsFooter from '@/vue/components/layout/LmsFooter.vue';
import { createPinia } from 'pinia';

export function initFooter(footer: HTMLElement) {
  let navigationItems: Typo3NavigationItem[] = [];
  let metaNavigationFooter: Typo3NavigationItem[] = [];
  const navigationFooter: Typo3NavigationItem[] = JSON.parse(footer.dataset.navigationFooter ?? '[]');
  const memberLogos: Logo[] = JSON.parse(footer.dataset.memberLogos ?? '[]');
  const appIcons: Logo[] = JSON.parse(footer.dataset.appIcons ?? '[]');
  const socialMedia: FooterIcons[] = JSON.parse(footer.dataset.socialMedia ?? '[]');
  let fairPath = '';
  let navigationCustomHeader: Typo3CustomHeaderNavigation | null = null;
  let customContactAddress: ContactDetails | null = null;

  const header: HTMLElement | null = document.querySelector('.new-header');

  if (header) {
    navigationItems = JSON.parse(header.dataset.mainNavigation ?? '[]');
    metaNavigationFooter = JSON.parse(header.dataset.metaNavigationFooter ?? '[]');
    fairPath = header.dataset.fairPath ?? '';
    navigationCustomHeader = header.dataset.customHeaderNavigation ? JSON.parse(header.dataset.customHeaderNavigation ?? '[]') : null;
    customContactAddress = header.dataset.customContactAddress ? JSON.parse(header.dataset.customContactAddress ?? '[]') : null;
  }

  createApp(LmsFooter, {
    navigationItems,
    metaNavigationFooter,
    fairPath,
    memberLogos,
    appIcons,
    socialMedia,
    navigationFooter,
    navigationCustomHeader,
    customContactAddress,
  })
    .use(i18n)
    .use(createPinia())
    .mount(footer);
}
