import { createApp } from 'vue';
import i18n from '@/translations';
import LmsHeroImage from 'lms-components/src/components/data-display/LmsHeroImage.vue';
import { createPinia } from 'pinia';

export function initHeroImage(heroImage: HTMLElement) {
    // FallbackImage
    let fallbackImage = null;
    if (heroImage.dataset.landingpageForegroundImageDesktop && heroImage.dataset.landingpageForegroundImageMobile) {
        fallbackImage = {
            desktop: structureMedia(parseJson(heroImage.dataset.landingpageForegroundImageDesktop)),
            mobile: structureMedia(parseJson(heroImage.dataset.landingpageForegroundImageMobile))
        };
    }

    // HeroImage
    let heroMedia = null;
    if (heroImage.dataset.fairHeaderImageDesktop && heroImage.dataset.fairHeaderImageMobile) {
        // check type of desktop (img or video)
        const heroMediaDesktop =  structureMedia(parseJson(heroImage.dataset.fairHeaderImageDesktop));

      if (heroMediaDesktop) {
        if (heroMediaDesktop.tag === 'img') {
          heroMedia = {
            desktop: heroMediaDesktop,
            mobile: structureMedia(parseJson(heroImage.dataset.fairHeaderImageMobile))
          };
        } else {
          heroMedia = {
            desktop: heroMediaDesktop,
            mobile: fallbackImage?.mobile || null,
          };
        }
      }

    }

    // Box
    const hideBox = heroImage.dataset.fairHeaderImageTeaserHidden ?? '';
    const boxPosition = hideBox === '1' ?
        'none' :
        (heroImage.dataset.fairHeaderImageTeaserPosition ?? '');
    const headline = heroImage.dataset.fairHeaderImageTeaserHeadline ?? '';
    const text = heroImage.dataset.fairHeaderImageTeaserText ?? '';
    const countdown = {
        date: parseJson(heroImage.dataset.fairHeaderTeaserCountdownDate),
        format: parseJson(heroImage.dataset.fairHeaderTeaserCountdownDateFormat)
    };
    // Links
    let primaryLink = '';
    let secondaryLink = '';
    if (heroImage.dataset.fairHeaderImageTeaserLink) {
      primaryLink = parseJson(heroImage.dataset.fairHeaderImageTeaserLink);
      primaryLink = Array.isArray(primaryLink) ? (primaryLink[0] || '') : primaryLink;
    }

    if (heroImage.dataset.fairHeaderImageTeaserLinkExtra) {
      secondaryLink = parseJson(heroImage.dataset.fairHeaderImageTeaserLinkExtra);
      secondaryLink = Array.isArray(secondaryLink) ? (secondaryLink[0] || '') : secondaryLink;
    }
    const links = {primaryLink, secondaryLink};
    const internalFairUid= heroImage.dataset.internalFairUid;

    createApp(LmsHeroImage, {
        heroMedia,
        fallbackImage,
        boxPosition,
        headline,
        text,
        links,
        countdown,
        internalFairUid
    })
        .use(i18n)
        .use(createPinia())
        .mount(heroImage);
}

function structureMedia(data: any): any {
    let media: any = null;

    if (data) {
        const parsingMedia = data.length ? data[0] : data;
        if (parsingMedia) {
            if (parsingMedia.tag === 'img') {
                media = {
                    focusArea: parseJson(parsingMedia.attributes["data-focus-area"]),
                    src: parsingMedia.attributes.src,
                    alt: parsingMedia.attributes.alt,
                    tag: parsingMedia.tag,
                    width: parsingMedia.attributes.width,
                    height: parsingMedia.attributes.height,
                };
            } else if (parsingMedia.tag === 'video') {
                media = {
                    src: parsingMedia.children[0].attributes.src,
                    tag: parsingMedia.tag,
                };
            }

        }
    }

    return media;
}

function parseJson(data: any): any {
    try {
        return data ? JSON.parse(data) : null;
    } catch (e) {
        console.error('Failed to parse JSON', e);
        return null;
    }
}
