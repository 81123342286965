import global from './global';
import ui from 'lms-components/src/translations/de/ui';
import forms from 'lms-components/src/translations/de/forms';
import header from 'lms-components/src/translations/de/header';
import fairCalendar from 'lms-components/src/translations/de/fairCalendar';
import footer from './footer';
export default {
  global,
  ui,
  forms,
  header,
  fairCalendar,
  footer,
};
